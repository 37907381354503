


















import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { DeletePostViewModel } from '../viewmodels/delete-post-viewmodel'

@Observer
@Component
export default class DeletePost extends Vue {
  @Provide() vm = new DeletePostViewModel()
}
