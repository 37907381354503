import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { DeletingAbstract } from '../abstract/delete-abstract'

export class DeletePostViewModel extends DeletingAbstract {
  @observable postId = ''
  @observable loading = false;

  @asyncAction *deleteDependencies() {
    try {
      this.loading = true
      const res = yield apiService.apiHandler.adminDeletePost(this.postId)
      if (res) {
        snackController.success('Post and dependencies have deleted')
        this.postId = ''
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }
}
